.complaintstable {
    margin-top: 24px;
    padding: 16px;
    background-color: #F3F3F3 !important;
    border-radius: 8px;
  }
  
  .complaintstable .MuiDataGrid-virtualScroller {
    /* min-height: 400px !important; */
    min-height: 50vh !important;
  }


  
  .complaintstable .MuiDataGrid-toolbarContainer {
    margin-bottom: 12px;
  }
  
  .complaintstable .MuiDataGrid-columnHeaders div {
    background-color: #F3F3F3 !important;
    border-color: transparent !important;
  }
  
  .complaintstable .MuiDataGrid-row {
    background-color: white !important;
    border-radius: 4px !important;
    margin: 3px 0px !important;
  }
  
  .complaintstable .MuiDataGrid-cell {
    border: 0px !important;
  }


  /* customer complaints table - new complaint page */

  .customerComplaintsTable {
    margin-top: 24px;
    padding: 16px;
    background-color: #F3F3F3 !important;
    border-radius: 8px;
  }

  .customerComplaintsTable .MuiDataGrid-columnHeaders div {
    background-color: #F3F3F3 !important;
    border-color: transparent !important;
  }

   .customerComplaintsTable .MuiDataGrid-virtualScroller{
    /* min-height: 400px !important; */
    min-height: 20vh !important;
  }

  
  .customerComplaintsTable .MuiDataGrid-toolbarContainer {
    margin-bottom: 12px;
  }
  
  .customerComplaintsTable .MuiDataGrid-columnHeaders div {
    background-color: #F3F3F3 !important;
    border-color: transparent !important;
  }
  
  .customerComplaintsTable .MuiDataGrid-row {
    background-color: white !important;
    border-radius: 4px !important;
    margin: 3px 0px !important;
  }
  
  .customerComplaintsTable .MuiDataGrid-cell {
    border: 0px !important;
  }