.description {
    overflow: hidden;
    color: var(--Text-Light, #696969);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    white-space: wrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
}